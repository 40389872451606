@import '_variables';

// Global reusable classes

.header1 {
  font-size: 2rem;
  font-weight: $font-bold;
}

.header3 {
  color: $color-grey;
}

.base-link {
  color: $color-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.justify-center {
  justify-self: center;
}

.no-display {
  display: none;
}

// Global reusable classes
.mt-5 {
  margin-top: 0.313rem;
}
.mt-10 {
  margin-top: 0.625rem;
}
.mt-15 {
  margin-top: 0.938rem;
}
.mt-20 {
  margin-top: 1.250rem;
}
.mt-25 {
  margin-top: 1.563rem;
}
.mt-30 {
  margin-top: 1.875rem;
}

.mb-5 {
  margin-bottom: 0.313rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.mb-15 {
  margin-bottom: 0.938rem;
}
.mb-20 {
  margin-bottom: 1.250rem;
}
.mb-25 {
  margin-bottom: 1.563rem;
}
.mb-30 {
  margin-bottom: 1.875rem;
}

.ml-5 {
  margin-left: 0.313rem;
}
.ml-10 {
  margin-left: 0.625rem;
}
.ml-15 {
  margin-left: 0.938rem;
}
.ml-20 {
  margin-left: 1.250rem;
}
.ml-25 {
  margin-left: 1.563rem;
}
.ml-30 {
  margin-left: 1.875rem;
}

.mr-5 {
  margin-right: 0.313rem;
}
.mr-10 {
  margin-right: 0.625rem;
}
.mr-15 {
  margin-right: 0.938rem;
}
.mr-20 {
  margin-right: 1.250rem;
}
.mr-25 {
  margin-right: 1.563rem;
}
.mr-30 {
  margin-right: 1.875rem;
}

.pt-5 {
  padding-top: 0.313rem;
}
.pt-10 {
  padding-top: 0.625rem;
}
.pt-15 {
  padding-top: 0.938rem;
}
.pt-20 {
  padding-top: 1.250rem;
}
.pt-25 {
  padding-top: 1.563rem;
}
.pt-30 {
  padding-top: 1.875rem;
}

.pb-5 {
  padding-bottom: 0.313rem;
}
.pb-10 {
  padding-bottom: 0.625rem;
}
.pb-15 {
  padding-bottom: 0.938rem;
}
.pb-20 {
  padding-bottom: 1.250rem;
}
.pb-25 {
  padding-bottom: 1.563rem;
}
.pb-30 {
  padding-bottom: 1.875rem;
}

.pl-5 {
  padding-left: 0.313rem;
}
.pl-10 {
  padding-left: 0.625rem;
}
.pl-15 {
  padding-left: 0.938rem;
}
.pl-20 {
  padding-left: 1.250rem;
}
.pl-25 {
  padding-left: 1.563rem;
}
.pl-30 {
  padding-left: 1.875rem;
}

.pr-5 {
  padding-right: 0.313rem;
}
.pr-10 {
  padding-right: 0.625rem;
}
.pr-15 {
  padding-right: 0.938rem;
}
.pr-20 {
  padding-right: 1.250rem;
}
.pr-25 {
  padding-right: 1.563rem;
}
.pr-30 {
  padding-right: 1.875rem;
}