@import '_variables';
@import '_classes';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

html, body, #root {
  position: relative;
  height: 100%;
  color: black;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
