@import 'style/_variables.scss';

.menuDrawer {
  &_submenuIndent {
    padding-left: 1rem;
  }
  &_userEmail {
    font-size: 0.7rem;
  }
  &_userId {
    font-size: 0.5rem;
  }
}