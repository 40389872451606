@import 'style/_variables.scss';

.signIn {
  display: grid;
  grid-auto-rows: 100%;
  height: 100%;
  grid-template-columns: 40% max-content auto;
  &_leftSection {
    display: grid;
    justify-items: center;
    align-content: center;
    background: $gray-linear-gradient;
    min-height: $height-without-appbar;
  }
  &_centerSection {
    padding: 1.875rem 0.625rem;
    min-width: 3.063rem;
  }
  &_rightSection {
    display: grid;
    justify-items: left;
    align-content: center;
    max-width: 31.250rem;
    &__bottomLinks {
      display: grid;
      grid-template-columns: repeat(2, max-content);
    }
  }
  &_facebookIcon {
    position: relative;
    top: -1px;
    height: 1.063rem;
    color: white;
  }
  &_jarIcon {
   width: 90%;
  }
}