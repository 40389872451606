@import 'style/_variables.scss';

.reportDialog {
  &_infoText {
    text-align: center;
  }
  &_reportTitle {
    font-weight: $font-regular;
    font-size: 0.9rem;
  }
}