// Global variables
$bg-gradient-top-gray: #DDD7E6;
$bg-gradient-bottom-gray: #FEFEFE;
$bg-gradient-top-purpure: #2B0096;
$bg-gradient-bottom-purpure: #E9649B;
$color-primary: #3f51b5;
$color-grey: #546e7a;

// Font weights are determinate by Montserrat Google Font styles
$font-regular: 400;
$font-semiBold: 600;
$font-bold: 700;

$appbar-height: 64px;
$height-without-appbar: calc(100vh - #{$appbar-height}); 

$gray-linear-gradient: linear-gradient($bg-gradient-top-gray, $bg-gradient-bottom-gray);

// Mixins
@mixin webkitInput {
  input {
    &:-webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,
    -webkit-autofill:active {
      border: 2px solid white;
      border-radius: .6rem;
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
  }
}