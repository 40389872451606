@import 'style/_variables.scss';

.header {
  justify-content: space-between;
  &_icons {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
  }
  &_logo {
    text-decoration: none;
    cursor: pointer;
  }
  &_logoTitle {
    font-size: 1.4rem;
    font-weight: $font-bold;
    color: white;
  }
}