@import 'style/_variables.scss';

.customerService {
  &_cardHeaderWrapper {
    display: grid;
    grid-template-columns: 1fr 33.6%;
    grid-gap: 2%;
    align-items: center;
  }
  &_loader {
    display: grid;
    justify-content: center;
    margin: 1rem 0;
  }
  &_dataError {
    display: grid;
    justify-content: center;
    margin: 1rem 0;
    color: red;
  }
  &_statusInfo {
    font-size: 1rem;
  }
  &_imagesTitle {
    font-size: 0.9rem;
    font-weight: $font-bold;
  }
  &_imageWrapper {
    width: 100%;
    display: grid;
    justify-items: center;
    &_image {
      display: block;
      max-width: 100%;
      margin-bottom: 35px;
      border: 1px solid #000000;
    }
  }
  &_actionsWrapper {
    margin: 16px 8px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    align-items: center;
  }
}